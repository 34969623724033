import React from "react"

import { shortcodes } from "../components/shortcodes"
import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { PostList } from "../components/PostList"
import { PostListItem } from "../components/PostListItem"
import { graphql } from "gatsby"
import { includes, some } from "lodash"
import ReactMarkdown from "react-markdown"
import { formatDistanceToNow } from "date-fns"
import {
  ContentFilterContext,
  ContentFilterSwitcher,
} from "../components/ContentFilter"

const quotationMarks = `'"“”`

const PinboardIndex: React.FC<MHPageProps> = (props) => {
  const { data } = props
  const { contentFilter } = React.useContext(ContentFilterContext)
  const lastUpdated = formatDistanceToNow(
    new Date(data.siteBuildMetadata.buildTime),
    { addSuffix: true }
  )

  return (
    <>
      <SEO title="My Pinboard" />

      <header className="n-type-normal oneColumn">
        <div className="n-spacing-bottom-medium">
          <h1 className="n-text-centered n-vspacing-small">My Pinboard</h1>
          <p>
            A list of articles, sites and other resources that I found
            interesting or useful and decided to save to{" "}
            <a
              href="https://pinboard.in/u:marcohamersma"
              target="_blank"
              className="g-link"
              rel="nofollow"
            >
              my Pinboard account
            </a>
            .
          </p>
          <p>
            Some of these are just useful tools, others are articles that inform
            how I look at the world and myself. Some of it is about stuff that's
            on my mind now, some of it I've had saved ages ago, but only
            recently read. That's how it goes, I guess. Nevertheless, it's a way
            of seeing what I'm thinking about.
          </p>
          <div className="n-type-small n-text-centered n-color-mid n-vspacing-small">
            <em>Last update: {lastUpdated}</em>
          </div>
        </div>
      </header>

      <ContentFilterSwitcher />

      <PostList>
        {data.allFeedPinboard?.edges.map(({ node }) => {
          let text = (node.content ?? "").trim()
          const date = formatDistanceToNow(new Date(node.date), {
            addSuffix: true,
          })

          const subjects = node.dc.subject?.split(" ")
          let include = contentFilter === "all"

          if (subjects?.length > 0) {
            if (
              contentFilter === "sound" &&
              some(
                ["sounddesign", "gameaudio", "sound"],
                (i) => subjects.indexOf(i) >= 0
              )
            ) {
              include = true
            }
            if (
              contentFilter === "webdev" &&
              some(
                ["development", "javascript", "UX", "web"],
                (i) => subjects.indexOf(i) >= 0
              )
            ) {
              include = true
            }
          }

          if (!include) return null

          if (
            includes(quotationMarks, text[0]) &&
            includes(quotationMarks, text[text.length - 1])
          ) {
            text = text
              .slice(1, -1)
              .split("\n")
              .map((i) => "> " + i)
              .join("\n>\n")
          }

          return (
            <PostListItem
              key={node.link}
              title={node.title}
              icon="🔗"
              subtitle={`added ${date}`}
              link={node.link}
              id={node.link}
            >
              <ReactMarkdown className="markdown" components={shortcodes}>
                {text}
              </ReactMarkdown>
            </PostListItem>
          )
        })}
      </PostList>
    </>
  )
}

export default PinboardIndex

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allFeedPinboard(limit: 40, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date
          content
          link
          dc {
            subject
          }
        }
      }
    }
    siteBuildMetadata {
      buildTime
    }
  }
`
